@import "../../../../../styles/theme";


.Export__Button {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 8px;
  padding-left: 32px;

  .Export__Button--loading > button {
    padding-right: 48px;
  }
}

fieldset {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 16px 24px;
  margin-bottom: 16px;

  @media only screen and (max-width: 576px) {
    padding: 24px 16px;
  }

  legend {
    padding: 0 8px;

    + div {
      width: 70%;
      max-width: 800px;

      @media only screen and (max-width: 1200px) {
        width: 80%;
        max-width: initial;
      }

      @media only screen and (max-width: 988px) {
        width: 100%;
      }
    }
  }
}
