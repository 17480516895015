@import "../../../styles/theme";

.notfound__title {
    font-size: 24px;
    margin-top: 0;
}

.notfound__body {
    font-size: 16px;
}
