@import "../../../styles/theme";

.undermaintaince__title {
    font-size: 24px;
    margin-top: 0;
}

.undermaintaince__body {
    font-size: 16px;
}
