@import "../../../styles/theme";

.layout__container {
  display: flex;
  height: 100%;
  padding-top: 60px;
}

.layout__content {
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;
}

.layout__overlay {
  position: absolute;
  top: 60px;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 50;
  background: $template-overlay;
  display: none;

  @media only screen and (max-width: 800px) {
    display: block;
  }
}
