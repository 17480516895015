/* ----------------------- fonts ----------------------- */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"), url(./fonts/montserrat.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Montserrat";
    font-style: medium;
    font-weight: 500;
    src: local("Montserrat Medium"), local("Montserrat-Medium"), url(./fonts/montserrat-medium.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Montserrat";
    font-style: bold;
    font-weight: 700;
    src: local("Montserrat Bold"), local("Montserrat-Bold"), url(./fonts/montserrat-bold.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* ----------------------- arabic ---------------------- */
@font-face {
    font-family: "Tajawal";
    font-style: normal;
    font-weight: 400;
    src: local("Tajawal Regular"), local("Tajawal-Regular"), url(./fonts/tajawal.woff2) format("woff2");
    unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}

@font-face {
    font-family: "Tajawal";
    font-style: medium;
    font-weight: 500;
    src: local("Tajawal Medium"), local("Tajawal-Medium"), url(./fonts/tajawal-medium.woff2) format("woff2");
    unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}

@font-face {
    font-family: "Tajawal";
    font-style: bold;
    font-weight: 700;
    src: local("Tajawal Bold"), local("Tajawal-Bold"), url(./fonts/tajawal-bold.woff2) format("woff2");
    unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
