@import "../../../styles/theme";

.login__container {
  flex: 1;
}

.login__form {

  .login__formmeta {
    font-size: 12px;
  }

  .login__formheader {
    font-size: 24px;
    margin: 8px 0 18px;

    @media only screen and (max-width: 440px) {
      font-size: 16px;
    }
  }

  .login__button {
    margin-top: 16px;
  }
}

.login__forgetpass {
  text-align: right;

  > a {
    text-decoration: none;
    font-weight: 500;
    color: #12264b;
  }
}
