/* --------------------- variables --------------------- */
$template-color: #1a202c;
$template-background: #f9f9f9;
$template-background-secondary: rgb(174, 231, 194);
$template-overlay: rgba(0, 0, 0, 0.25);

/* --------------------- secondary --------------------- */
$secondary-separator: rgb(174, 231, 194);
$secondary-background: #f6f8fc;

/* edf4ff navbar ---------------------- */
$navbar-background: rgb(174, 231, 194);
$navbar-border: #f0eeed;
$navbar-usericon: #4c97d2;
/* ---------------------- sidebar ---------------------- */
$sidebar-active: #edf4ff;
$sidebar-default: #767676;
$sidebar-active-icon: #12264b;
$sidebar-background: #fff;

/* --------------------- search box -------------------- */
$search-background: #f9f9f9;
$search-placeholder: #767676;

/* ----------------------- toast ----------------------- */
$toast-success-color: #009f82;
$toast-success-background: #e0f2ef;
$toast-error-color: #ea3d3d;
$toast-error-background: #ffe9e9;

/* ----------------------- mixins ---------------------- */
@mixin transition($value) {
    -webkit-transition: $value;
    -moz-transition: $value;
    -o-transition: $value;
    transition: $value;
}

@mixin transform($value) {
    -webkit-transform: $value;
    -moz-transform: $value;
    -o-transform: $value;
    transform: $value;
}

@mixin transformOrigin($value) {
    -webkit-transform-origin: $value;
    -moz-transform-origin: $value;
    -o-transform-origin: $value;
    transform-origin: $value;
}
