@import "theme";
@import "fonts";
@import "homzmart-components/dist/index.css";
@import "~bootstrap-4-grid/css/grid.min.css";

/* ---------------------- general ---------------------- */

h1._1uNB9 {
  color: rgb(63, 138, 89)!important;
}




body {
  margin: 0;
  background-color: "red";
  font-size: 14px;

  * {
    color: $template-color;
    box-sizing: border-box;
    font-family: Montserrat, Tajawal, Arial, Helvetica, sans-serif;
  }
}

html,
body,
#root,
.App {
  height: 100%;
}

/* ---------------------- general ---------------------- */
h1.page-title {
  font-size: 24px;
  margin-top: 0;

  @media only screen and (max-width: 550px) {
    font-size: 18px;
  }
}

.centered-content {
  margin: auto;
  text-align: center;
  padding-bottom: 60px;
}
