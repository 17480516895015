@import "../../../../../styles/theme";

.search__container {
	display: flex;
	background: $search-background;
	margin: auto 50px;
	min-width: 500px;
	height: 40px;
	border-radius: 4px;

	@media only screen and (max-width: 1050px) {
		display: none;
	}
}

.search__icon {
	margin: auto 0 auto 20px;
}

.search__input {
	margin: 7px 15px;
	border: none;
	outline: none;
	background: transparent;
	width: 100%;

	&::placeholder {
		color: $search-placeholder;
	}
}
