@import "theme";

.app__container {
  display: flex;
  height: 100%;
  padding-top: 60px;
}

.app__content {
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;
}

.app__page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  &.app__page--secondary {
    background: rgb(174, 231, 194);

    > :global(div > div.ps__rail-y) {
      opacity: 0 !important;
    }
  }
}
