@import "../../../styles/theme";

.auth__container {
  @media only screen and (max-width: 585px) {
    padding: 40px 24px;
  }
}

.auth__wrapper {
  margin: 43px;
  background: $secondary-background;
  border-radius: 4px;
  flex: 1;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 585px) {
    margin: 0;
  }
}

.auth__grid {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.auth__illustration {
  display: flex;
  width: 65%;

  @media only screen and (max-width: 1300px) {
    width: 55%;
  }

  @media only screen and (max-width: 991px) {
    display: none;
  }

  > svg {
    margin: auto;
    max-width: 70%;
    max-height: 70%;

    @media only screen and (max-width: 1300px) {
      width: 400px;
    }
  }
}

.auth__separator {
  width: 2px;
  background: $secondary-separator;
  margin: 68px 0 68px -2px;

  @media only screen and (max-width: 991px) {
    display: none;
  }
}


.auth__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;

  .auth__logo {
    height: 40px;
  }

  .auth__logometa {
    font-weight: 500;
    color: #12264b;
    margin-top: 16px;
    margin-bottom: 0;
  }

  > div,
  > form {
    margin-top: 32px;
    width: 320px;

    @media only screen and (max-width: 1110px) {
      width: 280px;
      overflow: hidden;
      white-space: nowrap;
    }

    @media only screen and (max-width: 991px) {
      width: 320px;
      overflow: initial;
      white-space: initial;
    }

    @media only screen and (max-width: 440px) {
      width: 100%;
      padding: 0 24px;
    }
  }
}
