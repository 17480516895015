// @import "../../../../../styles/theme";

.orderManage {
    background: #e7faec;
    border-radius: 12px;

    :global .btn {
        font-size: 22px !important;
        line-height: 1.5;
    }

    .greenDot {
        color: #2bc155;
        fill: #2bc155;
    }
}

.revenueChartBar {
    margin-bottom: -30px;

    @media only screen and (max-width: 1600px) and (min-width: 1200px) {
        margin-bottom: 20px;
        float: none;
        padding-left: 20px;
    }

    @media only screen and (max-width: 575px) {
        margin-bottom: 20px;
        float: none;
        padding-left: 10px;
    }
}

.featureCard {
    background-repeat: no-repeat;
    background-position: top right;
}

.noDropIcon {
    &:after {
        display: none;
    }
}






