@import "../../../../../styles/theme";

.sidebar__container {
    width: 68px;
    padding: 16px 0;
    flex-direction: column;
    overflow: hidden;
    z-index: 100;
    background: $sidebar-background;
    @include transition(width 0.3s ease-in-out);

    @media only screen and (max-width: 800px) {
        width: 168px;
        position: fixed;
        height: 100%;
        left: -168px;
        @include transition(left 0.2s ease);

        &.sidebar__container--active {
            left: 0;
        }

        .sidebar__label {
            opacity: 1;
            margin-left: 0px;
        }
    }

    &.sidebar__container--active {
        width: 168px;

        .sidebar__label {
            opacity: 1;
            margin-left: 0px;
        }
    }
}

.sidebar__element {
    display: flex;
    height: 48px;
    cursor: pointer;
    width: 168px;
    text-decoration: none;

    svg {
        width: 19px;
        margin: auto 25px;
        margin-right: 8px;
    }

    &.sidebar__element--active {
        background: $sidebar-active;

        .sidebar__label {
            color: $sidebar-active-icon;
        }

        svg > path {
            fill: $sidebar-active-icon;
        }
    }
}

.sidebar__label {
    user-select: none;
    color: $sidebar-default;
    font-weight: 500;
    margin: auto;
    opacity: 0;
    margin-left: 17px;
    @include transition(all 0.05s ease-in);
}

// position: fixed;
//     height: 100%;
//     width: 168px;
//     z-index: 100;
//     left: 0;
