@import "../../../styles/theme";

.page__wrapper {
    display: flex !important;
    padding: 30px;
    flex-direction: column;

    @media only screen and (max-width: 550px) {
        padding: 24px;
    }
}
