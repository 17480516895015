@import "../../../styles/theme";

.Page__Title {
	padding: 24px;
	margin-bottom: 24px;
	font-size: 24px;
    font-weight: bold;
    color: #3b4249;

  @media only screen and (max-width: 550px) {
    font-size: 18px;
  }
}


.Page__body {
	font-size: 10px;

	@media only screen and (max-width: 5px) {
		font-size: 14px;
	}
}
