@import "../../../styles/theme";

:global(.toast__base) {
    > button > svg {
        width: 24px;
        height: 24px;
    }
}

:global(.toast__success) {
    background: $toast-success-background;
    border: 1px solid $toast-success-color;

    > button > svg {
        fill: $toast-success-color;
    }

    .toast__header {
        color: $toast-success-color;
    }

    .toast__details {
        color: $toast-success-color;
    }
}

:global(.toast__error) {
    background: $toast-error-background;
    border: 1px solid $toast-error-color;

    > button > svg {
        fill: $toast-error-color;
    }

    .toast__header {
        color: $toast-error-color;
    }

    .toast__details {
        color: $toast-error-color;
    }
}

:global(.toast__warn) {
    background: #ffe16a
    // border: 1px solid $toast-error-color;

    // > button > svg {
    //     fill: $toast-error-color;
    // }

    // .toast__header {
    //     color: $toast-error-color;
    // }

    // .toast__details {
    //     color: $toast-error-color;
    // }
}

.toast__container {
    display: flex;
}

.toast__icon {
    padding-right: 8px;
}

.toast__header {
    font-weight: bold;
    margin: 4px 0;
}

.toast__details {
    margin: 0;
}
