@import "../../../../../styles/theme";

.navbar__container {
    position: fixed;
    height: 60px;
    width: 100%;
    display: flex;
    z-index: 10;
    border-bottom: 1px solid $navbar-border;
    background: $navbar-background;

    .navbar__menuhandle {
        margin: auto;
        margin-left: 24px;
        cursor: pointer;
        display: none;

        @media only screen and (max-width: 800px) {
            display: block;
        }
    }

    .navbar__logo {
        margin: 13px 24px;

        @media only screen and (max-width: 991px) {
            display: none;
        }
    }

    .navbar__logosimple {
        margin: 12px 24px;
        display: none;

        @media only screen and (max-width: 991px) {
            display: block;
        }
    }

    .navbar__flag {
        margin: 13px 0px;
        border-radius: 4px;
        transform: scale(0.9)

        
    }

    

    .navbar__spacer {
        flex: 1;
    }

    .navbar__icon {
        margin: auto;

        @media only screen and (max-width: 550px) {
            margin-left: 8px;
        }
    }

    .navbar__handle {
        display: flex;
    }

    .navbar__username {
        margin: auto 8px;
        text-transform: capitalize;

        @media only screen and (max-width: 550px) {
            display: none;
        }
    }
}

.navbar__usermenu {
    margin: auto 24px auto 18px;

    > div:last-child {
        @media only screen and (max-width: 550px) {
            margin-top: 8px;
        }
    }
}

.navbar__usericon {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    background: $navbar-usericon;
    font-size: 12px;

    > span {
        color: #fff;
        margin: auto;
    }
}

.navbar__menuicon {
    margin-right: 8px;
}

.navbar__menuname {
    display: none !important;
    text-transform: capitalize;

    @media only screen and (max-width: 550px) {
        display: flex !important;
    }
}
